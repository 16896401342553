import * as React from "react";
import {CognitoIdentityProviderClient, InitiateAuthCommand, RespondToAuthChallengeCommand, ConfirmDeviceCommand, UpdateDeviceStatusCommand} from "@aws-sdk/client-cognito-identity-provider";
import {getAuthenticationHelper} from "&/AuthenticationHelper";
import {Amplify as AmplifyCore} from "@aws-amplify/core";
import * as Auth from "aws-amplify/auth";
import {Hub} from "aws-amplify/utils";
import {Amplify} from "aws-amplify";
import {AMPLIFY_CONFIG} from "./amplify-config";

// Name to use when using QR Code Authenticator App setup
const MFA_APP_NAME = "MaxID: Manual Review" + process.env.REACT_APP_MFA_SUFFIX;

// Set up Amplify
Amplify.configure(AMPLIFY_CONFIG);
Hub.listen("auth", ({payload: {event, data}}) => {
    // Redirect back to desired page after login
    if (event === "customOAuthState") window.location.replace(data);
});

/** AuthContext React context */
const AuthContext = React.createContext({
    user: null, mfa: null,
    signOut: () => Promise.resolve(),
    changePassword: () => Promise.resolve(),
    fetchMFAPrefs: () => Promise.resolve(),
    setupMobileMFA: () => Promise.resolve(),
    setupAppMFA: () => Promise.resolve()
});

/** Decode Amplify's key-value set of user attributes */
const mapAttributesToDetails = (attributes) => {
    const {given_name: firstName, family_name: lastName, email: loginName, phone_number: mobile} = attributes;
    return {firstName, lastName, loginName, mobile};
};

/** AuthProvider Component */
export const AuthProvider = ({children}) => {
    // Establish initial state
    const [user, setUser] = React.useState(null);
    const [mfa, setMFA] = React.useState({});
    
    // Attempt to restore the session of a previously logged-in user
    const checkUser = React.useCallback(async () => {
        try {
            const user = mapAttributesToDetails(await Auth.fetchUserAttributes());
            const tokenStore = await AmplifyCore.Auth.authOptions.tokenProvider.tokenOrchestrator.getTokenStore();
            const deviceMetadata = await tokenStore.getDeviceMetadata(user.loginName);
            
            // If no device previously stored, register a new one and save the details
            if (!deviceMetadata) try {
                const authKeys = await tokenStore.getAuthKeys();
                const {deviceKey, deviceGroupKey, randomPasswordKey} = await registerDevice(user.loginName);
                await tokenStore.getKeyValueStorage().setItem(authKeys.deviceKey, deviceKey);
                await tokenStore.getKeyValueStorage().setItem(authKeys.deviceGroupKey, deviceGroupKey);
                await tokenStore.getKeyValueStorage().setItem(authKeys.randomPasswordKey, randomPasswordKey);
            } catch (ex) {
                console.error(ex);
            }
            // Otherwise, update when the device was last seen
            else await Auth.rememberDevice();
            
            setUser(user);
        } catch (ex) {
            setUser(null);
            await Auth.signOut();
            await Auth.signInWithRedirect({customState: window.location.href});
        }
    }, []);
    
    // Check the user as soon as the component mounts
    React.useEffect(() => {checkUser()}, [checkUser]);
    
    // Sign the user out of Amplify, and clear state
    const signOut = async () => {
        try {
            await Auth.signOut();
        } finally {
            setUser(null);
        }
    };
    
    // Change the logged-in user's password in Amplify
    const changePassword = async (oldPassword, newPassword) => {
        await Auth.updatePassword({oldPassword, newPassword});
    };
    
    // Fetch the current user's MFA preferences and linked app devices
    const fetchMFAPrefs = React.useCallback(async () => {
        setMFA((mfa) => ({...mfa, fetching: true}));
        
        const {enabled = []} = await Auth.fetchMFAPreference();
        const smsEnabled = enabled.includes("SMS");
        const appEnabled = enabled.includes("TOTP");
        const devices = await Auth.fetchDevices();
        const device = devices.find(({attributes: {device_name}}) => device_name === "Authenticator App");
        const {id: deviceId, createDate: lastChanged} = device ?? {};
        
        setMFA(({app = {}}) => ({
            sms: {enabled: smsEnabled},
            app: {...app, enabled: appEnabled, deviceId, lastChanged}
        }));
    }, []);
    
    // Setup MFA via SMS
    const setupMobileMFA = async () => {
        setMFA((mfa) => ({...mfa, fetching: true}));
        
        try {
            await Auth.updateMFAPreference({sms: "ENABLED", totp: mfa?.app?.enabled ? "ENABLED" : "DISABLED"});
            await fetchMFAPrefs();
        } catch (ex) {
            setMFA((mfa) => ({...mfa, fetching: false}));
            throw ex;
        }
    };
    
    // Setup MFA via TOTP Authenticator Apps
    const setupAppMFA = async (code) => {
        setMFA((mfa) => ({...mfa, fetching: true}));
        
        try {
            // If called while TOTP is already enabled, disable and forget device
            if (mfa?.app?.enabled) {
                await Auth.forgetDevice({device: {id: mfa?.app?.deviceId}});
                await Auth.updateMFAPreference({totp: "DISABLED", sms: mfa?.sms?.enabled ? "ENABLED" : "DISABLED"});
                await fetchMFAPrefs();
            }
            // If called without a code, begin TOTP setup
            else if (!code) {
                const totp = await Auth.setUpTOTP();
                const setupURI = (await totp.getSetupUri(MFA_APP_NAME, user.loginName)).toString();
                const {sharedSecret} = totp;
                
                setMFA(({sms}) => ({sms, app: {setupURI, sharedSecret}}));
            }
            // If called with a code, enable TOTP and register a new device for the Authenticator App
            else {
                await Auth.verifyTOTPSetup({code});
                await Auth.updateMFAPreference({totp: "ENABLED", sms: mfa?.sms?.enabled ? "ENABLED" : "DISABLED"});
                await registerDevice(user.loginName, "Authenticator App");
                await fetchMFAPrefs();
            }
        } catch (ex) {
            setMFA((mfa) => ({...mfa, fetching: false}));
            throw ex;
        }
    };
    
    return (
        <AuthContext.Provider value={{user, signOut, changePassword, mfa, fetchMFAPrefs, setupMobileMFA, setupAppMFA}}>
            {user && children}
        </AuthContext.Provider>
    );
};

/** useAuth hook for accessing AuthContext */
export const useAuth = () => {
    const context = React.useContext(AuthContext);
    
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider.");
    }
    
    return context;
};

/**
 * Call AWS Cognito IdP SDK commands to create and confirm a new device
 * @param {String} username - who the device belongs to
 * @param {String} [DeviceName] - name of the new device to create and confirm
 * @returns {Promise<{deviceGroupKey: string, deviceKey: string, randomPasswordKey: string}>}
 */
async function registerDevice(username, DeviceName) {
    // Initialise Cognito IdP Client and get details relevant to future commands
    const CognitoClient = new CognitoIdentityProviderClient({region: AMPLIFY_CONFIG.Auth.Cognito.region});
    const ClientId = AMPLIFY_CONFIG.Auth.Cognito.userPoolClientId;
    const poolId = AMPLIFY_CONFIG.Auth.Cognito.userPoolId;
    const poolName = poolId.split("_")[1] || "";
    const USERNAME = username;
    // Initialise the amplify AuthenticationHelper class and retrieve current access token
    const helper = await getAuthenticationHelper(poolName);
    const AccessToken = (await Auth.fetchAuthSession())?.tokens?.accessToken?.toString?.();
    // Begin CUSTOM_AUTH flow, which is configured to verify the current access token, as the Hosted UI doesn't track devices
    const {ChallengeName, Session} = await CognitoClient.send(new InitiateAuthCommand({ClientId, AuthFlow: "CUSTOM_AUTH", AuthParameters: {USERNAME}}));
    const ChallengeResponses = {USERNAME, ANSWER: AccessToken};
    // Respond to the challenge with access token to register a new device
    const {AuthenticationResult: {NewDeviceMetadata}} = await CognitoClient.send(new RespondToAuthChallengeCommand({ClientId, ChallengeName, Session, ChallengeResponses}));
    const {DeviceKey, DeviceGroupKey} = NewDeviceMetadata ?? {};
    
    // Generate SRP for the device and confirm it with Cognito
    await helper.generateHashDevice(DeviceKey, DeviceGroupKey);
    await CognitoClient.send(new ConfirmDeviceCommand({
        AccessToken, DeviceKey, DeviceName, DeviceSecretVerifierConfig: {
            Salt: btoa(String.fromCodePoint(...helper.getSaltToHashDevices().match(/.{2}/g).map((hex) => parseInt(hex, 16)))),
            PasswordVerifier: btoa(String.fromCodePoint(...helper.getVerifierDevices().match(/.{2}/g).map((hex) => parseInt(hex, 16))))
        }
    }));
    
    // Remember the device!
    await CognitoClient.send(new UpdateDeviceStatusCommand({AccessToken, DeviceKey, DeviceRememberedStatus: "remembered"}));
    
    // Return details of the newly created device
    return {deviceKey: DeviceKey, deviceGroupKey: DeviceGroupKey, randomPasswordKey: helper.getRandomPassword()};
}